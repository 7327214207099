import AccountProcessStep0001 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-0001/2.0.0/index.vue'

export default {
  data() {
    return {
      failDialogVisible: false
    }
  },
  activated() {
    this.$refs['accountProcessStep0001'].init({
      getParams() {
        return new Promise(resolve => {
          resolve({
            account: '',
            password: '',
            accountId: '',
            accountType: '',
          })
        })
      }
    })
  },
  components: {
    AccountProcessStep0001
  },
  methods: {
    loginResult(trainAccount) {
        switch (trainAccount.accountStatus) {
          case 1:
            this.$message.success(trainAccount.accountStatusText);
            this.back();
            break;
          case 2:
            this.$router.replace({
              name: 'admin-train-account-check'
            })
            break;
          case 3:
            this.$message.error(trainAccount.accountStatusText);
            break;
        }
    },
    failSubmit() {
      this.failDialogVisible = false;
      this.back();
    },
    beforeBack() {
      this.failDialogVisible = true;
    },
    back() {
      this.$router.go(-1);
    },
    toRegister() {
      this.$router.push({
        name: 'admin-train-account-register'
      })
    },
  },
};
